import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

export const WhatsappButton = () => {
    const phoneNumber = '5554999379184'; // Substitua pelo número de WhatsApp da sua empresa (código do país incluído)
    const defaultMessage = 'Olá, gostaria de solicitar um orçamento para o desenvolvimento de um site. Poderia me fornecer mais informações?'; // Mensagem padrão


    return (
        <a
            href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(defaultMessage)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="fixed bottom-8 right-8 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300 flex items-center"
        >
            <FaWhatsapp className="mr-2" size={24} />
            Faça seu orçamento gratuitamente
        </a>
    );
};
