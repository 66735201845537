import React, { useState } from 'react';

const services = [
    { title: 'Contato Inicial e Reunião', shortDesc: 'Entender as necessidades do cliente e apresentar a empresa.', fullDesc: 'Nesta fase, discutimos suas necessidades, objetivos e expectativas para o projeto, além de compreender o escopo inicial.', img: require('../../assets/images/meeting.png') },
    { title: 'Proposta e Escopo do Projeto', shortDesc: 'Formalizar a oferta e definir claramente o que será entregue.', fullDesc: 'Desenvolvemos uma proposta personalizada, com detalhes técnicos, prazos, escopo e custos para a execução do projeto.', img: require('../../assets/images/proposal.png') },
    { title: 'Contratação e Acordo', shortDesc: 'Formalizar o compromisso entre as partes.', fullDesc: 'Após a aprovação da proposta, firmamos um acordo formal com os detalhes do projeto e definimos os marcos principais.', img: require('../../assets/images/contract.png') },
    { title: 'Desenvolvimento', shortDesc: 'Construir o site ou sistema conforme o design aprovado.', fullDesc: 'A equipe técnica começa o desenvolvimento de acordo com o escopo definido, com atualizações frequentes sobre o progresso.', img: require('../../assets/images/programming.png') },
    { title: 'Entrega e Treinamento', shortDesc: 'Lançar o site ou sistema e treinar o cliente para utilizá-lo.', fullDesc: 'Após a conclusão do projeto, fazemos a entrega final e oferecemos treinamento para garantir o uso adequado da solução.', img: require('../../assets/images/analysis.png') },
    { title: 'Suporte Pós-Lançamento', shortDesc: 'Garantir uma transição suave e suporte contínuo.', fullDesc: 'Oferecemos suporte contínuo e manutenções para garantir que a solução continue a operar perfeitamente.', img: require('../../assets/images/suport.png') },
];

const Services = () => {
    const [activeCard, setActiveCard] = useState(null);

    return (
        <section id="services" className="py-24  bg-gradient-to-r from-cyan-500 to-blue-500">
            <div className="container mx-auto py-10 px-4">  
                <h2 className="text-4xl font-bold text-center mb-8">Nossos Serviços</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {services.map((service, index) => (
                        <div
                            key={index}
                            onMouseEnter={() => setActiveCard(index)}
                            onMouseLeave={() => setActiveCard(null)}
                            className={`p-6 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105 ${activeCard === index ? 'scale-105 bg-gray-300' : 'bg-white'
                                }`}
                        >
                            <div className="text-4xl flex items-center justify-center mb-4"><img className="w-40" alt='meeting' src={service.img} /></div>
                            <h3 className="text-xl font-bold text-center mb-4">{service.title}</h3>
                            <p className="px-16 text-center text-gray-600">
                                {activeCard === index ? service.fullDesc : service.shortDesc}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Services;